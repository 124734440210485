const CarStatus = (props) => {
  return (
    <div className='column'>
      <h2
        style={{
          color: 'white',
          fontSize: 36,
          fontFamily: 'Prompt-Bold',
          position: 'relative',
          left: 16,
          top: 3,
        }}
      >
        {props.status}
      </h2>
    </div>
  )
}

export default CarStatus
