import BoardTitle from './BoardTitle'
import OrderAmount from './OrderAmount'
import OrderId from './OrderId'
import CarStatus from './CarStatus'
import PaymentProcessing from './PaymentProcessing'
import EnrollmentSMS from './EnrollmentSMS'

const ImageBoard = (props) => {
  return (
    <div className='column' style={props.style}>
      <BoardTitle name={props.name} src={props.src} alt={props.alt} />

      <div className='row'>
        <CarStatus status={props.status} />
        <div className='column' style={{ position: 'relative', left: 200 }}>
          {props.carImg && (
            <img
              src={props.carImg}
              alt='car'
              style={{ maxHeight: 150, maxWidth: 150 }}
            />
          )}
        </div>
      </div>

      {props.status !== 'CLEAR' && (
        <div className='row'>
          <OrderAmount
            amount={props.amount}
            value={props.value}
            onKeyPress={props.onKeyPress}
            onChange={props.onChange}
            paidOrPending={props.paidOrPending}
          />
          <OrderId
            orderId={props.orderId}
            orderIdValue={props.orderIdValue}
            orderIdOnKeyPress={props.orderIdOnKeyPress}
            orderIdOnChange={props.orderIdOnChange}
          />
        </div>
      )}

      {props.status !== 'CLEAR' &&
        (props.paymentResult || props.paymentProcessing) && (
          <PaymentProcessing
            paymentStatus={props.paymentStatus}
            src={props.paymentSrc}
            lastDigits={props.lastDigits}
          />
        )}

      {/* should only show on window side "props.window" is a crutch to make sure this only shows on the window side for now */}
      {props.paidOrPending === 'PAID' &&
        props.window &&
        props.status === 'NOT ENROLLED' && (
          <EnrollmentSMS
            valueSMS={props.valueSMS}
            onKeyPressSMS={props.onKeyPressSMS}
            onChangeSMS={props.onChangeSMS}
            enrollNumber={props.enrollNumber}
          ></EnrollmentSMS>
        )}

      {props.status !== 'CLEAR' && (
        <div
          className='d-grid gap-2'
          style={{ position: 'absolute', bottom: 20 }}
        >
          {props.btnPresent &&
            !props.paymentResult &&
            !props.paymentProcessing && (
              <button
                onClick={props.onClick}
                style={{
                  backgroundColor: '#00dd71',
                  color: '#011624',
                  border: 'none',
                  borderRadius: 10,
                  position: 'relative',
                  fontSize: 24,
                  fontFamily: 'Prompt-Bold',
                  padding: 15,
                  minHeight: 60,
                  width: '46vw',
                  left: '4%',
                }}
              >
                Pay with Card on File
              </button>
            )}
          {/* need to add logic to display this when card is expired */}
          {!props.btnPresent &&
            props.status === 'NOT ENROLLED' &&
            props.paidOrPending === 'PENDING' && (
              <h2
                style={{
                  color: 'white',
                  fontSize: 24,
                  fontFamily: 'Prompt-Bold',
                  position: 'relative',
                  left: '50%',
                }}
              >
                Swipe, Insert, or Tap to Pay
              </h2>
            )}
          {!props.btnPresent &&
            props.status === 'ENROLLED' &&
            props.paidOrPending === 'PENDING' &&
            props.paymentStatus === 'Card Expired' && (
              <h2
                style={{
                  color: 'white',
                  fontSize: 24,
                  fontFamily: 'Prompt-Bold',
                  position: 'relative',
                  left: '50%',
                }}
              >
                Swipe, Insert, or Tap to Update Card
              </h2>
            )}
          {/* handles the button showing for not enrolled */}
          {props.paidOrPending === 'PAID' &&
            props.window &&
            props.status === 'NOT ENROLLED' && (
              <button
                onClick={props.onClick}
                style={{
                  backgroundColor: '#ffc107',
                  color: '#011624',
                  border: 'none',
                  borderRadius: 10,
                  position: 'relative',
                  fontSize: 24,
                  fontFamily: 'Prompt-Bold',
                  padding: 15,
                  minHeight: 60,
                  width: '46vw',
                  left: '4%',
                }}
              >
                Send SMS to Enroll
              </button>
            )}
        </div>
      )}
    </div>
  )
}

export default ImageBoard
