export async function smsEnrollment(
  restaurantCode,
  plate,
  gatewayId,
  token,
  phoneNumber,
  name,
  requestId
) {
  // staging url
  // for now the plate is just going to be the order id for testing purposes
  return fetch(
    `https://zmktgxqm7e.execute-api.us-west-2.amazonaws.com/staging/${restaurantCode}/pay-by-plate/${plate}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        gatewayId: `${gatewayId}`,
        token: `${token}`,
        phoneNumber: `${phoneNumber}`,
        name: `${name}`,
        requestId: `${requestId}`,
      }),
      //   "gatewayId" to transactionInfo.gatewayId,
      //   "token" to transactionInfo.token,
      //   "phoneNumber" to phoneNumber,
      //   "name" to name,
      //   "requestId" to transactionInfo.requestId
    }
  ).then((data) => data.json())
}
