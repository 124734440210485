import ImageBoard from './ImageBoard'
import WindowLogo from './images/master_icon_button_small.png'
import MenuLogo from './images/icon_button_small_primary_default.png'
import PaymentLoading from './images/progress_linear_secondary.png'
import Grid from '@mui/material/Grid'
import { useAuth0 } from '@auth0/auth0-react'
import { useState, useRef, useEffect } from 'react'
import orderIdToPaymentStatus from './orderIdToPaymentStatus'
import { paymentValidation } from './paymentValidation'
import { smsEnrollment } from './smsEnrollment'

const ImageFullBoard = () => {
  // sets the car image after listening to event
  const [menuCarImg, setMenuCarImg] = useState(null)

  // handles the state of the token; getting and setting if necessary
  const [token, setToken] = useState(null)
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const socketUrl =
    'wss://tjuhrm4ph0.execute-api.us-west-2.amazonaws.com/beta?location=bf_test_01&token=5FA20060FD134F9C8DD68A66B0141E17'
  const getToken = async () => {
    const accessToken = await getAccessTokenSilently()
    setToken(accessToken)
  }
  if (token == null && isAuthenticated) {
    getToken()
  }

  // handles the status of the window
  const [menuStatus, setMenuStatus] = useState('CLEAR')
  const [windowStatus, setWindowStatus] = useState('CLEAR')

  // for the window board only
  const [paidOrPending, setPaidOrPending] = useState('')

  // button logic
  const [menuBtnPresent, setMenuBtnPresent] = useState(false)
  const [windowBtnPresent, setWindowBtnPresent] = useState(false)
  const [menuProcessingPayment, setMenuProcessingPayment] = useState(false)
  const [windowProcessingPayment, setWindowProcessingPayment] = useState(false)

  // state handling for after payment is fully processed
  const [menuProcessed, setMenuProcessed] = useState(false)
  const [windowProcessed, setWindowProcessed] = useState(false)

  const [menuPaymentStatus, setMenuPaymentStatus] = useState('Processing')
  const [windowPaymentStatus, setWindowPaymentStatus] = useState('Processing')

  const [reRunEvent, setReRunEvent] = useState(false)

  // used to handle the order amount and order id
  const [menuOrderAmt, setMenuOrderAmt] = useState('')
  const [inputAmt, setInputAmt] = useState('')
  const [menuOrderId, setMenuOrderId] = useState('')
  const [inputId, setInputId] = useState('')
  const [windowOrderAmt, setWindowOrderAmt] = useState('')
  const [windowOrderId, setWindowOrderId] = useState('')
  const [plate, setPlate] = useState('')
  const [ccLastDigits, setCCLastDigits] = useState('')

  // array of mappings of (k,v) pairs of (orderId,paymentStatus)
  const [idToPayStatusArr, setIdToPayStatusArr] = useState([])

  // handles enrollment
  const [enrollNumber, setEnrollNumber] = useState('')
  const [curEnrollNumber, setCurEnrollNumber] = useState('')

  const inputOrderAmt = (e) => {
    if (e.key === 'Enter') {
      // add the dollar sign here
      setMenuOrderAmt(e.target.value)
      setInputAmt('')
    }
  }

  // assume this is always entered SECOND; breaks if this is entered first
  const inputOrderId = (e) => {
    if (e.key === 'Enter') {
      setMenuOrderId(e.target.value)
      setInputId('')

      const response = updateAmtAndId(
        plate,
        e.target.value,
        'Processing',
        menuOrderAmt
      )
      response.then(() => {
        setReRunEvent((reRunEvent) => !reRunEvent)
        console.log('after hitting enter on orderId')
        console.log(idToPayStatusArr)
      })
    }
  }

  // window only; handles enrollment logic for not enrolled orders
  const inputEnrollNumber = (e) => {
    if (e.key === 'Enter') {
      setEnrollNumber(e.target.value)
      setCurEnrollNumber('')

      // sample url:  https://zmktgxqm7e.execute-api.us-west-2.amazonaws.com/staging/famousdaves_qa_corp_1/pay-by-plate/873543
      // sample body: {"gatewayId":"SHIFT4_USA","token":"8647023768220216","phoneNumber":"5108283594","name":"Customer","requestId":"016538754839"}
      const response = smsEnrollment(
        'famousdaves_qa_corp_1',
        plate,
        'SHIFT4_USA',
        '8647023768220216',
        enrollNumber,
        'Anthony',
        '016538754839'
      )
      response.then((data) => {
        setReRunEvent((reRunEvent) => !reRunEvent)
        console.log('sms sent')
        console.log(data)
      })
    }
  }

  // used to update the order information (amt and id)
  const updateAmtAndId = async (plate, orderId, paymentStatus, orderAmt) => {
    setIdToPayStatusArr(
      idToPayStatusArr.map((item) => {
        if (item.plate === plate) {
          return { ...item, orderId, paymentStatus, orderAmt }
        } else {
          return item
        }
      })
    )
  }

  // used to update the pay status AFTER clicking the pay button
  const updatePaymentStatusForOrderId = (orderId, paymentStatus) => {
    setIdToPayStatusArr(
      idToPayStatusArr.map((item) => {
        if (item.orderId === orderId) {
          return { ...item, paymentStatus }
        } else {
          return item
        }
      })
    )
  }

  // used to set up the order with the plate on menu enter event
  const createOrderUsingPlate = async (plate) => {
    const currOrder = new orderIdToPaymentStatus()
    currOrder.plate = plate
    // if any of these below values are still set by the time the window comes, it is wrong
    // these are default values to prevent any breaking behavior, but this means logic is wrong
    currOrder.orderId = '0000'
    currOrder.paymentStatus = 'Processing'
    currOrder.orderAmt = '00.00'
    setIdToPayStatusArr([...idToPayStatusArr, currOrder])
  }

  const menuBtnHandler = () => {
    // make sure the order id and order amount is set up first
    if (menuOrderAmt && menuOrderId) {
      console.log('menu button was pressed')
      setMenuProcessingPayment(true)
      setMenuBtnPresent(false)

      // submit the payload
      const response = paymentValidation(idToPayStatusArr[0])
      response.then((res) => {
        console.log(res)
        setMenuProcessingPayment(false)
        setMenuProcessed(true)

        // make sure the payment was a success
        if (res.status === 'COMPLETED') {
          setMenuPaymentStatus('Payment Complete')
          setCCLastDigits(res.payment_source.card.last_digits)
          updatePaymentStatusForOrderId(menuOrderId, 'Payment Complete')
        } else {
          setMenuPaymentStatus('Processing')
        }
        setReRunEvent((reRunEvent) => !reRunEvent)
      })
    }
    // this should not work if there is no order set up properly
    else {
      console.log(
        'Error invalid state, orderID and orderAmount need to be inputted first'
      )
    }
  }

  const windowBtnHandler = () => {
    console.log('window button was pressed')
    setWindowProcessingPayment(true)
    setWindowBtnPresent(false)

    // submit the payload
    const response = paymentValidation(idToPayStatusArr[0])
    response.then((res) => {
      console.log(res)
      setWindowProcessingPayment(false)
      setWindowProcessed(true)

      // make sure the payment was a success
      if (res.status === 'COMPLETED') {
        setWindowPaymentStatus('Payment Complete')
        setCCLastDigits(res.payment_source.card.last_digits)
        updatePaymentStatusForOrderId(windowOrderId, 'Payment Complete')
        setPaidOrPending('PAID')
      } else {
        setWindowPaymentStatus('Processing')
      }
      setReRunEvent((reRunEvent) => !reRunEvent)
    })
  }

  // New status for web socket connection. init to null and false
  const eventSocketRef = useRef(null)
  const [eventWaitingToReconnect, setEventWaitingToReconnect] = useState(null)
  const [isEventSocketOpen, setEventSocketOpen] = useState(false)

  const handleReconnectEventSocket = () => {
    if (eventSocketRef.current) {
      // Connection failed
      // console.log('event socket closed by server');
    } else {
      // Cleanup initiated from app side, can return here, to not attempt a reconnect
      // console.log('event socket closed by app component unmount');
      return
    }

    if (eventWaitingToReconnect) {
      return
    }

    // Parse event code and log
    setEventSocketOpen(false)
    // console.log('event socket closed');

    // Setting this will trigger a re-run of the effect,
    // cleaning up the current websocket, but not setting
    // up a new one right away
    setEventWaitingToReconnect(true)

    // This will trigger another re-run, and because it is false,
    // the socket will be set up again
    setTimeout(() => setEventWaitingToReconnect(null), 5000)
  }

  useEffect(() => {
    // watch the current array
    // console.log(idToPayStatusArr);
    // rerender the screen when a reconnect needs to happen or reRun is invoked
    if (eventWaitingToReconnect) {
      return
    }

    // Only set up the websocket once
    if (!eventSocketRef.current) {
      const carCameraSocket = new WebSocket(
        `${socketUrl}&route=checkpoint_current_order_payment`
      )
      eventSocketRef.current = carCameraSocket
      // console.log('carCameraSocket.readyState', carCameraSocket.readyState);

      carCameraSocket.onopen = () => {
        setEventSocketOpen(true)
        // Set Initial Data
        carCameraSocket.send(
          JSON.stringify({
            action: 'checkpoint_current_order_payment',
            location: 'bf_test_01',
          })
        )
      }

      carCameraSocket.onclose = () => {
        handleReconnectEventSocket()
      }
    }

    // receive and process messages
    eventSocketRef.current.onmessage = (evt) => {
      // if the keepalive message occurs, ignore it
      if (evt.data === 'keepalive') {
        return
      }
      // console.log("on reading the event message the arr is ");
      // console.log(idToPayStatusArr);
      const data = JSON.parse(evt.data)
      console.log(data)

      if (data.event_type === 'DRIVE_THRU_ORDER_ENTER') {
        setMenuCarImg(data.vehicle.image_path)
        setPlate(data.vehicle.vehicle_id)

        createOrderUsingPlate(data.vehicle.vehicle_id)
        // resp.then(() => {
        // setReRunEvent(reRunEvent => !reRunEvent);
        //   console.log("order created on enter: ");
        //   console.log(idToPayStatusArr);
        // });

        let menuStatus =
          data.vehicle.status === 'ACTIVE' ? 'ENROLLED' : 'NOT ENROLLED'
        setMenuStatus(menuStatus)

        let btnForEnrolled = menuStatus === 'ENROLLED' ? true : false
        setMenuBtnPresent(btnForEnrolled)
        setMenuProcessed(false)
        setMenuPaymentStatus('Processing')
        setMenuProcessingPayment(false)
      } else if (data.event_type === 'DRIVE_THRU_ORDER_EXIT') {
        setMenuCarImg(null)
        setMenuStatus('CLEAR')
        setMenuBtnPresent(false)
        setMenuProcessed(false)
        setMenuProcessingPayment(false)
        setMenuOrderAmt('')
        setMenuOrderId('')
      }

      if (data.event_type === 'DRIVE_THRU_PAYMENT_ENTER') {
        let windowStatus =
          data.vehicle.status === 'ACTIVE' ? 'ENROLLED' : 'NOT ENROLLED'

        // only show the button on window side if the button was not pressed before
        let curPaymentStatus = idToPayStatusArr[0].paymentStatus
        let successfulPayment = curPaymentStatus === 'Payment Complete'

        setWindowStatus(windowStatus)
        setWindowProcessingPayment(false)
        setWindowOrderAmt(idToPayStatusArr[0].orderAmt)
        setWindowOrderId(idToPayStatusArr[0].orderId)

        if (successfulPayment) {
          console.log('payment was completed successfully')
          setWindowPaymentStatus('Payment Complete')
          setWindowBtnPresent(!successfulPayment)
          setPaidOrPending('PAID')
          setWindowProcessed(false)
        } else {
          setWindowPaymentStatus(curPaymentStatus)
          setWindowBtnPresent(windowStatus === 'ENROLLED')
          setPaidOrPending('PENDING')
          setWindowProcessed(curPaymentStatus === 'Card Expired')
        }
      } else if (data.event_type === 'DRIVE_THRU_PAYMENT_EXIT') {
        setWindowStatus('CLEAR')
        setWindowBtnPresent(false)
        setWindowPaymentStatus('Processing')
        setWindowProcessed(false)
        setWindowProcessingPayment(false)
        setPaidOrPending('')
        setEnrollNumber('')

        // dequeue the front of the line
        idToPayStatusArr.splice(0, 1)
        setIdToPayStatusArr([...idToPayStatusArr])
      }
    }

    return () => {
      // console.log('Event socket Cleanup');
      eventSocketRef.current.close()
      eventSocketRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventWaitingToReconnect, reRunEvent])

  return (
    <Grid container>
      <ImageBoard
        name='Window'
        src={WindowLogo}
        alt='Window Icon'
        status={windowStatus}
        window={true}
        btnPresent={windowBtnPresent}
        onClick={windowBtnHandler}
        amount={windowOrderAmt}
        orderId={windowOrderId}
        paymentResult={windowProcessed}
        paymentProcessing={windowProcessingPayment}
        paymentStatus={windowPaymentStatus}
        lastDigits={ccLastDigits}
        paymentSrc={windowProcessingPayment ? PaymentLoading : null}
        paidOrPending={paidOrPending}
        enrollNumber={enrollNumber}
        valueSMS={curEnrollNumber}
        onKeyPressSMS={inputEnrollNumber}
        onChangeSMS={(e) => setCurEnrollNumber(e.target.value)}
        style={{
          background: '#02263d',
          height: '100vh',
          width: '50vw',
          position: 'relative',
        }}
      ></ImageBoard>
      <ImageBoard
        name='Menu'
        src={MenuLogo}
        alt='Menu Icon'
        carImg={menuCarImg}
        status={menuStatus}
        window={false}
        btnPresent={menuBtnPresent}
        onClick={menuBtnHandler}
        amount={menuOrderAmt}
        value={inputAmt}
        onKeyPress={inputOrderAmt}
        onChange={(e) => setInputAmt(e.target.value)}
        orderId={menuOrderId}
        orderIdValue={inputId}
        orderIdOnKeyPress={inputOrderId}
        orderIdOnChange={(e) => setInputId(e.target.value)}
        paidOrPending={'STUB'}
        paymentResult={menuProcessed}
        paymentProcessing={menuProcessingPayment}
        paymentStatus={menuPaymentStatus}
        lastDigits={ccLastDigits}
        paymentSrc={menuProcessingPayment ? PaymentLoading : null}
        style={{
          background: '#011624',
          height: '100vh',
          width: '50vw',
          position: 'relative',
        }}
      ></ImageBoard>
    </Grid>
  )
}

export default ImageFullBoard
