class orderIdToPaymentStatus {
  constructor(plate, orderId, paymentStatus, orderAmt) {
    this.plate = plate
    this.orderId = orderId
    this.paymentStatus = paymentStatus
    this.orderAmt = orderAmt
  }
}

export default orderIdToPaymentStatus
