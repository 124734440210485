const OrderId = (props) => {
  return (
    <div className='column'>
      <div>
        <p
          style={{
            position: 'relative',
            left: 16,
            top: -10,
            fontSize: 12,
            fontFamily: 'Prompt-Regular',
            color: 'white',
          }}
        >
          Order ID Last 4 Digits
        </p>
      </div>
      <div>
        <h2
          style={{
            position: 'relative',
            left: 16,
            top: -30,
            fontSize: 24,
            fontFamily: 'Prompt-Bold',
            color: 'white',
          }}
        >
          {props.orderId}
        </h2>
        {!props.orderId && (
          <input
            type='text'
            value={props.orderIdValue}
            onKeyPress={props.orderIdOnKeyPress}
            onChange={props.orderIdOnChange}
            style={{
              position: 'relative',
              width: '90%',
              left: 16,
              top: -20,
              fontSize: 24,
              fontFamily: 'Prompt-Bold',
              outline: 'none',
              border: 'none',
              borderBottom: '1px solid white',
              background: 'transparent',
              color: 'white',
            }}
          />
        )}
      </div>
    </div>
  )
}

export default OrderId
