const BoardTitle = (props) => {
  return (
    <div>
      <div className='column' style={{ height: 10, width: 40 }}>
        <img
          src={props.src}
          alt={props.alt}
          height='40'
          width='40'
          style={{ position: 'relative', top: 28, left: 16 }}
        />
      </div>
      <div className='column'>
        <h1
          style={{
            fontSize: 21,
            fontFamily: 'Prompt-Regular',
            position: 'relative',
            top: 17,
            left: 16,
            color: 'white',
          }}
        >
          {props.name} Board 1
        </h1>
      </div>
    </div>
  )
}

export default BoardTitle
