export async function paymentValidation(order) {
  // staging url
  // for now the plate is just going to be the order id for testing purposes
  return fetch(
    `https://592drb6pu3.execute-api.us-west-2.amazonaws.com/staging/v1/bf_01/pay-by-plate/${order.plate}/pay`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: `${order.orderAmt}`,
        requestId: `${order.orderId}`,
      }),
    }
  ).then((data) => data.json())
}
