const OrderAmount = (props) => {
  return (
    <div className='column'>
      <div>
        <p
          style={{
            position: 'relative',
            left: 16,
            top: -10,
            fontSize: 12,
            fontFamily: 'Prompt-Regular',
            color: 'white',
          }}
        >
          Order Amount
        </p>
      </div>
      <div>
        <h2
          style={{
            position: 'relative',
            left: 16,
            top: -30,
            fontSize: 24,
            fontFamily: 'Prompt-Bold',
            color: 'white',
          }}
        >
          {props.amount}
        </h2>
        {!props.amount && (
          <input
            type='text'
            value={props.value}
            onKeyPress={props.onKeyPress}
            onChange={props.onChange}
            style={{
              position: 'relative',
              width: '90%',
              left: 16,
              top: -20,
              fontSize: 24,
              fontFamily: 'Prompt-Bold',
              outline: 'none',
              border: 'none',
              borderBottom: '1px solid white',
              backgroundColor: 'transparent',
              color: 'white',
            }}
          />
        )}

        {props.paidOrPending && props.paidOrPending === 'PAID' && (
          <h2
            style={{
              position: 'relative',
              left: 16,
              top: -30,
              fontSize: 24,
              fontFamily: 'Prompt-Bold',
              color: '#00dd71',
            }}
          >
            {props.paidOrPending}
          </h2>
        )}
        {props.paidOrPending && props.paidOrPending === 'PENDING' && (
          <h2
            style={{
              position: 'relative',
              left: 16,
              top: -30,
              fontSize: 24,
              fontFamily: 'Prompt-Bold',
              color: '#ffc107',
            }}
          >
            {props.paidOrPending}
          </h2>
        )}
        {/* stub holder to preserve spacing for the menu board side; should ONLY be on menu side */}
        {props.paidOrPending && props.paidOrPending === 'STUB' && (
          <h2
            style={{
              position: 'relative',
              left: 16,
              top: -30,
              fontSize: 24,
              fontFamily: 'Prompt-Bold',
              color: '#011624',
            }}
          >
            {props.paidOrPending}
          </h2>
        )}
      </div>
    </div>
  )
}

export default OrderAmount
