import ExpiredLogo from './images/ignore_color_symbol_tintable_black.png'
const PaymentProcessing = (props) => {
  return (
    <div style={{ position: 'relative', top: '10%' }}>
      <div>
        {props.paymentStatus === 'Card Expired' ? (
          <img
            src={ExpiredLogo}
            alt='Expired'
            style={{
              height: '10vh',
              width: '25vw',
              position: 'relative',
              margin: 'auto',
              top: 33,
              left: '25%',
            }}
          />
        ) : (
          // dummy tag here used to preserve spacing
          <p style={{ opacity: 0 }}>1</p>
        )}
        <h2
          style={{
            color: 'white',
            fontSize: 34,
            fontFamily: 'Prompt-Bold',
            position: 'relative',
            margin: 'auto',
            width: '60%',
            bottom: 30,
            textAlign: 'center',
          }}
        >
          {props.paymentStatus}
        </h2>
      </div>
      <div>
        {!props.src && props.paymentStatus === 'Payment Complete' && (
          <h3
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: 'Prompt-Regular',
              position: 'relative',
              margin: 'auto',
              width: '60%',
              bottom: 30,
              textAlign: 'center',
            }}
          >
            payment made with VISA ****{props.lastDigits}
          </h3>
        )}
        {!props.src && props.paymentStatus === 'Card Expired' && (
          <h3
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: 'Prompt-Regular',
              position: 'relative',
              margin: 'auto',
              width: '60%',
              bottom: 30,
              textAlign: 'center',
            }}
          >
            Card is past its expiration date
          </h3>
        )}
        <img
          src={props.src}
          alt={props.alt}
          style={{
            position: 'relative',
            textAlign: 'center',
            left: '40%',
            bottom: 30,
            width: '10vw',
          }}
        />
      </div>
    </div>
  )
}

export default PaymentProcessing
