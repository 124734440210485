const EnrollmentSMS = (props) => {
  return (
    <>
      <div>
        {!props.enrollNumber && (
          <p
            style={{
              position: 'relative',
              left: 16,
              top: -10,
              fontSize: 12,
              fontFamily: 'Prompt-Regular',
              color: 'white',
            }}
          >
            Mobile Number
          </p>
        )}
        {!props.enrollNumber && (
          <h2
            style={{
              position: 'relative',
              left: 16,
              top: -30,
              fontSize: 24,
              fontFamily: 'Prompt-Bold',
              color: 'white',
            }}
          >
            {props.enrollNumber}
          </h2>
        )}

        {!props.enrollNumber && (
          <input
            type='text'
            value={props.valueSMS}
            onKeyPress={props.onKeyPressSMS}
            onChange={props.onChangeSMS}
            style={{
              position: 'relative',
              width: '90%',
              left: 16,
              top: -20,
              fontSize: 24,
              fontFamily: 'Prompt-Bold',
              outline: 'none',
              border: 'none',
              borderBottom: '1px solid white',
              backgroundColor: 'transparent',
              color: 'white',
            }}
          />
        )}
        <div>
          {!props.enrollNumber ? (
            <h3
              style={{
                color: 'white',
                fontSize: 34,
                fontFamily: 'Prompt-Bold',
                position: 'relative',
                left: 16,
                top: 3,
                textAlign: 'center',
              }}
            >
              Enter mobile number to enroll
            </h3>
          ) : (
            <h3
              style={{
                color: 'white',
                fontSize: 34,
                fontFamily: 'Prompt-Bold',
                position: 'relative',
                left: 16,
                top: 3,
                textAlign: 'center',
              }}
            >
              SMS sent to {props.enrollNumber}
            </h3>
          )}
        </div>
      </div>
    </>
  )
}

export default EnrollmentSMS
