import './App.css'
import ImageFullBoard from './ImageFullBoard'

const App = () => {
  return (
    <div>
      <ImageFullBoard></ImageFullBoard>
    </div>
  )
}

export default App
